




import { CommonRolePermissionDataResponse } from './CommonRolePermissionDataResponse';
import { BaseTokenResponse } from './BaseTokenResponse';    
export class CommonRolePermissionResponse extends BaseTokenResponse  {
  
  public items: CommonRolePermissionDataResponse[] = [];
  
}








import { CommonAccountDeskGridStateColumnSizeSettingDataRequest } from './CommonAccountDeskGridStateColumnSizeSettingDataRequest';
import { BaseRequest } from './BaseRequest';
export class CommonAccountDeskGridStateSetColumnSizeSettingsRequest extends BaseRequest {        
  
  public searchSchemaName: string = '';
  
  public gridColumnSizeSettings: CommonAccountDeskGridStateColumnSizeSettingDataRequest[] = [];
  
}





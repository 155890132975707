export class ValidationHelper {

  public static isValidEmailAddress(search: string): boolean {
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    return regexp.test(search);
  }

  public static isValidPhoneNumber(search: string): boolean {
    const regexp = new RegExp(/^\+?[0-9]+(?:\.[0-9]+)?$/);

    return regexp.test(search);
  }

  public static isValidFiscalCode(search: string): boolean {
    const regexp = new RegExp(/^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/);

    return regexp.test(search);
  }

}






import { CommonMenuGroupDataResponse } from './CommonMenuGroupDataResponse';
import { BaseTokenResponse } from './BaseTokenResponse';    
export class CommonMenuResponse extends BaseTokenResponse  {
  
  public groups: CommonMenuGroupDataResponse[] = [];
  
}




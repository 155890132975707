



import { CommonNotifyMenuBadgeChangeRequest } from './CommonNotifyMenuBadgeChangeRequest';
import { BaseTokenResponse } from './BaseTokenResponse';
export class CommonMenuBadgeResponse extends BaseTokenResponse {        
  
  public items: CommonNotifyMenuBadgeChangeRequest[] = [];
  
}









import { CommonAccountDeskGridStateColumnOrderSettingDataRequest } from './CommonAccountDeskGridStateColumnOrderSettingDataRequest';
import { BaseRequest } from './BaseRequest';
export class CommonAccountDeskGridStateSetColumnOrderSettingsRequest extends BaseRequest {        
  
  public searchSchemaName: string = '';
  
  public gridColumnOrderSettings: CommonAccountDeskGridStateColumnOrderSettingDataRequest[] = [];
  
}










import { BaseTokenResponse } from './BaseTokenResponse';
import { IBaseDtoItems } from './IBaseDtoItems';    
export class CommonCrudEntityGetListResponse<TDto> extends BaseTokenResponse  implements IBaseDtoItems<TDto> {
  
  public items: TDto[] = [];
  
}









import { BaseTokenResponse } from './BaseTokenResponse';
import { IBaseDtoItem } from './IBaseDtoItem';    
export class CommonCrudEntityGetResponse<TDto> extends BaseTokenResponse  implements IBaseDtoItem<TDto> {
  
  public item: TDto = null;
  
}







import { BasePersEditDataRequestWrapper } from './BasePersEditDataRequestWrapper';
import { BaseRequest } from './BaseRequest';
export class BaseEditRequest extends BaseRequest  {

  public uid: string =  '00000000-0000-0000-0000-000000000000';
  public rollbackAfterSave: boolean =  false;
  public persDataRequests: BasePersEditDataRequestWrapper[] =  [];
}





